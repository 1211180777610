import type { IAttachment } from '~coreTypes/attachment.model';

const getFile = (file: BlobPart, attachment: IAttachment) => {
  let blob = new Blob([file]);
  if (attachment.type === 'pdf') {
    blob = new Blob([file], {
      type: 'application/pdf'
    });
  }

  if (attachment.type === 'png') {
    blob = new Blob([file], {
      type: 'image/png'
    });
  }

  if (attachment.type === 'jpg') {
    blob = new Blob([file], {
      type: 'image/jpg'
    });
  }

  if (attachment.type === 'jpeg') {
    blob = new Blob([file], {
      type: 'image/jpeg'
    });
  }

  if (attachment.type === 'xml') {
    blob = new Blob([file], {
      type: 'application/xml'
    });
  }
  return blob;
};

const downloadFile = (file: BlobPart, attachment: IAttachment) => {
  const blob = getFile(file, attachment);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = attachment.name;

  return link;
};

export const downloadFileWithRevoke = (file: BlobPart, attachment: IAttachment) => {
  const link = downloadFile(file, attachment);
  link.click();
  URL.revokeObjectURL(link.href);
};

export const getTotalFilesSize = (filesSize: number[]): number => {
  return filesSize.reduce((accumulator, fileSize) => (accumulator += fileSize), 0);
};

export const formatToMBToBytes = (MB: number) => MB * 1048576;
